.ant-table-cell{
    background: white !important;
    font-size: 14px;
}
.ant-pagination-item ,.ant-pagination-item-link {
    border: 1px solid #E8E8E8 !important;
}

.ant-pagination-item-active{
    border: 1px solid #0866FF !important;
}

.ant-table-sticky-scroll {
    display: none !important;
}

.ant-pagination-options{
    display: none !important;
}
.selected-row > .ant-table-cell {
    background: #E6F4FF !important;
    cursor: pointer;
}

.row-click{
    cursor: pointer;
}