.MuiBarElement-root{
    rx: 8px !important;
    fill: #00CCFF !important;
}

.MuiChartsAxis-tick, .MuiChartsAxis-line{
    stroke: transparent !important;
}

.MuiChartsAxis-tickLabel{
    fill: #373737 !important;
    font-family: "Noto Sans KR", sans-serif !important;
}