.custom-button {
  --primary-color: #1890ff;
  /* Blue color */
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-btn-primary:disabled {
  color: rgba(255, 255, 255, 0.75);
  background: #0866FF80 !important;
}